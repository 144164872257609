@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
h1 {
  font-family: 'Anton', sans-serif;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Anton', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-image: linear-gradient(130deg, #2a0e66, #4f0cdf);
  background-color: #2a0e66;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}
.App-perspective {
  -webkit-perspective: 600px;
          perspective: 600px;
}
.App-perspective p {
  -webkit-transform: rotateY(30deg);
          transform: rotateY(30deg);
  padding-left: 5em;
  text-align: left;
  transition: -webkit-transform 0.2s cubic-bezier(.67,1.22,1,.55);
  transition: transform 0.2s cubic-bezier(.67,1.22,1,.55);
  transition: transform 0.2s cubic-bezier(.67,1.22,1,.55), -webkit-transform 0.2s cubic-bezier(.67,1.22,1,.55);
}
.App-perspective p:hover {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}
.App-title {
  font-size: 4em;
  margin: 0;
  line-height: .98em;
  -webkit-transform: rotateY(30deg);
          transform: rotateY(30deg);
  transition: -webkit-transform 0.2s cubic-bezier(.67,1.22,1,.55);
  transition: transform 0.2s cubic-bezier(.67,1.22,1,.55);
  transition: transform 0.2s cubic-bezier(.67,1.22,1,.55), -webkit-transform 0.2s cubic-bezier(.67,1.22,1,.55);

}
.App-title:hover {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}
.App-title:nth-child(2) {
  padding-left: .7em;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

footer {
  background-color: #4f0cdf;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5em 0;
  font-size: 0.8em;
}
footer table tr td {
  text-align: right;
  padding-left: 1em;
}
footer table tr td:first-child{
  text-align: left;
}
footer table tr td a {
  color: white;
}
