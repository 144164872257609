.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-image: linear-gradient(130deg, #2a0e66, #4f0cdf);
  background-color: #2a0e66;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}
.App-perspective {
  perspective: 600px;
}
.App-perspective p {
  transform: rotateY(30deg);
  padding-left: 5em;
  text-align: left;
  transition: transform 0.2s cubic-bezier(.67,1.22,1,.55);
}
.App-perspective p:hover {
  transform: rotateY(0deg);
}
.App-title {
  font-size: 4em;
  margin: 0;
  line-height: .98em;
  transform: rotateY(30deg);
  transition: transform 0.2s cubic-bezier(.67,1.22,1,.55);

}
.App-title:hover {
  transform: rotateY(0deg);
}
.App-title:nth-child(2) {
  padding-left: .7em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  background-color: #4f0cdf;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0;
  font-size: 0.8em;
}
footer table tr td {
  text-align: right;
  padding-left: 1em;
}
footer table tr td:first-child{
  text-align: left;
}
footer table tr td a {
  color: white;
}